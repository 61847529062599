import Dexie from 'dexie'
import { map, prop } from 'ramda'

const db = new Dexie('persistent_db')
db.version(1).stores({
 entries: '&src, blob, tag'
})

const storingNow = {}

function call(src: string, key: string, value?: any) {
  if (!storingNow[src] || !Array.isArray(storingNow[src][key]) || !storingNow[src][key].length) {
    return
  }

  storingNow[src][key].forEach((cb: Function) => cb && cb(value))
}

function store(
  src: string,
  tag: string,
  onLoaded?: (data: Blob) => void,
  onProgress?: (progress: number) => void,
  onError?: (status: number) => void
) {
  if (storingNow[src]) {
    storingNow[src].onLoaded.push(onLoaded)
    storingNow[src].onProgress.push(onProgress)
    storingNow[src].onError.push(onError)
    return
  }

  storingNow[src] = {
    onLoaded: [onLoaded],
    onProgress: [onProgress],
    onError: [onError],
  }

  const xhr = new XMLHttpRequest()
  xhr.onprogress = (event) => {
    const progress = event.total === 0 ? 2 : event.loaded / event.total
    call(src, 'onProgress', progress)
  }

  xhr.onload = xhr.onerror = () => {
    if (xhr.status === 200) {
      db.table('entries').add({
        src, tag, blob: xhr.response
      }).then(() => {
        call(src, 'onLoaded', xhr.response)
        delete storingNow[src]
      })
    } else {
      call(src, 'onError', xhr.status)
      delete storingNow[src]
    }
  }

  xhr.responseType = 'blob'
  xhr.open('GET', src, true)
  xhr.send()
}

async function remove(entries: string[]) {
  try {
    await db.table('entries')
      .where('src').anyOf(...entries)
      .delete()
  } catch (ignored) {
  }

  return true
}

async function load(
  src: string,
  tag: string,
  onProgress?: (progress: number) => void,
) {
  async function fallback() {
    return new Promise<string>((resolve, reject) => {
      store(src, tag, (blob) => {
        resolve(URL.createObjectURL(blob))
      }, onProgress, reject)
    })
  }

  const stored = await db.table('entries').get({ src })
  if (stored && stored.blob) {
    return URL.createObjectURL(stored.blob)
  } else {
    return fallback()
  }
}

async function list(tag: string): Promise<string[]> {
  // @ts-ignore
  return (await db.table('entries').where('tag').equals(tag)).toArray(map(prop('src')))
}

export {
  remove, load, list
}
