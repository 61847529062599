import React, { CSSProperties, ReactNode } from 'react';
import BaseKeyboard  from './BaseKeyboard'
import isIp from 'is-ip'
import { keyBackspace, keySend } from './keys'
import { ModalFooterAction } from '../BaseModal'
import { IQTheme } from '../../../../types'

export interface KeyboardIPProps {
  value?: string
  className?: string
  style?: CSSProperties
  interceptor?: (value: string) => string
  onSubmit?: (data: string) => void
  onChange?: (data: string) => void
  isOpened?: boolean
  theme?: IQTheme
  checkValidity?: (value?: number) => boolean
  title?: ReactNode
  persistent?: boolean
  footerActions?: ModalFooterAction[]
  onClose?: () => void
  onShow?: () => void
}

const layout = [
  ['A', 'B', '7', '8', '9'],
  ['C', 'D', '4', '5', '6'],
  ['E', 'F', '1', '2', '3'],
  [':', '.', '0', keyBackspace, {
    ...keySend,
    className: 'primary'
  }]
]

const KeyboardIP = (props: KeyboardIPProps) => {
  const {
    onChange = () => {},
    onSubmit = () => {}, ...keyboardProps
  } = props

  return <BaseKeyboard
    { ...keyboardProps }
    type={'tel'}
    checkValidity={(value) => !!value && isIp(value)}
    onSubmit={(data) => data && onSubmit(data)}
    onChange={(data) => data && onChange(data)}
    layout={layout}
  />
}

KeyboardIP.show = ({
  onChange = () => {},
  onSubmit = () => {},
  ...props
}: KeyboardIPProps) => BaseKeyboard.show({
  ...props, layout,
  type: 'tel',
  checkValidity: (value) => isIp(value || ''),
  onSubmit: (data) => data && onSubmit(data),
  onChange: (data) => data && onChange(data),
})

export default KeyboardIP
