import React, {
  ChangeEvent, CSSProperties, ReactNode, Ref, useState,
  useLayoutEffect, useRef
} from 'react'
import cn from 'classnames'
import { IQInputElement } from '../../../../types'

export interface CheckboxProps {
  name: string
  checked?: boolean
  className?: string
  inline?: boolean
  title?: string
  children?: ReactNode
  style?: CSSProperties
  autoFocus?: boolean
  hidden?: boolean
  onChange?: (value?: boolean) => void
  disabled?: boolean,
  inputRef?: Ref<IQInputElement<boolean>>
}

export default function (props: CheckboxProps) {
  const {
    className, style, name, title, children, inline, checked = false,
    hidden, disabled, inputRef: ref, onChange = () => {}
  } = props

  const inputRef = useRef<IQInputElement<boolean>>(null)
  const [value, setValue] = useState(checked)

  useLayoutEffect(
    () => {
      const el: Partial<IQInputElement<boolean>> | null = inputRef.current
      if (el) {
        el.isValid = () => true

        el.getValue = () => value

        el.setValue = (val: boolean) => {
          // setValue(val)
          // onChange(val)
          if (inputRef.current) {
            inputRef.current.checked = val
          }
        }
      }

      if (ref) {
        if (typeof ref === 'function') {
          ref(el as IQInputElement<boolean>)
        } else {
          (ref as any).current = el
        }
      }
    },
    [inputRef, ref, value]
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.checked)
    onChange(e.currentTarget.checked)
  }

  return <div
    className={cn('iq-checkbox', className, {
      inline, hidden,
    })}
    style={style}
  >
    <input
      ref={inputRef}
      disabled={disabled}
      id={`iq-checkbox-${name}`}
      name={name}
      type='checkbox'
      checked={value}
      onChange={handleChange}
    />
    <label htmlFor={`iq-checkbox-${name}`}>{ title }{ children }</label>
  </div>
}
