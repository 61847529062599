import React, { Ref, useCallback } from 'react'
import BaseInput, { BaseInputProps } from './BaseInput'
import { IQInputElement } from '../../../../types'

export type IQInputType = 'text' | 'password' | 'number' | 'float' | 'tel' | 'raw'

export interface TypedInputProps<T> extends BaseInputProps<T> {
  type?: IQInputType
  useKeyboardAction?: boolean
  stringify?: (value?: T) => string
  parse?: (value?: string) => T
  inputRef?: Ref<IQInputElement<T>>
}

export default function <T>(props: TypedInputProps<T> & { customValue: boolean }) {
  const { type = 'text', stringify, parse, useKeyboardAction, ...inputProps } = props

  const serialize = useCallback((value?: T): string => {
    if (stringify) {
      return stringify(value)
    }

    switch (type) {
      case 'text':
      case 'password':
      case 'tel':
      case 'number':
      case 'float':
        return String(value || '')
      case 'raw':
      default:
        throw new Error('Property stringify is required for raw inputs!')
    }
  }, [type])

  const deserialize = useCallback((value?: string): T | undefined => {
    if (parse) {
      return parse(value)
    }

    switch (type) {
      case 'text':
      case 'password':
      case 'tel':
        return value ? value as any as T : undefined
      case 'number':
        return (value ? Number.parseInt(value, 10) : undefined) as any as T
      case 'float':
        return (value ? Number.parseFloat(value) : undefined) as any as T
      case 'raw':
      default:
        throw new Error('Property parse is required for raw inputs!')
    }
  }, [type])

  return <BaseInput<T>
    type={type}
    stringify={serialize}
    parse={deserialize}
    {...inputProps}
  />
}
