import './cursor.scss'

let unsubscribe: Function | undefined = undefined

function isTouchScreen() {
  return window.matchMedia('(any-pointer: coarse)').matches
}

function initCursor(indicator: HTMLDivElement) {
  let indicatorTimer: number

  const hideIndicator = () => {
    indicator.classList.remove('visible')
  }

  const moveIndicator = (e: MouseEvent) => {
    indicator.classList.add('visible')
    indicator.style.left = e.clientX + 'px'
    indicator.style.top = e.clientY + 'px'

    window.clearTimeout(indicatorTimer)
    indicatorTimer = window.setTimeout(hideIndicator, 50)
  }

  const bounceIndicator = () => {
    indicator.classList.add('bounce')
  }

  const debounceIndicator = (e: MouseEvent) => {
    indicator.classList.remove('bounce')
    moveIndicator(e)
  }

  document.body.appendChild(indicator)
  document.addEventListener('mousedown', bounceIndicator, true)
  document.addEventListener('mousemove', moveIndicator, true)
  document.addEventListener('mouseup', debounceIndicator, true)

  return () => {
    document.body.removeChild(indicator)
    document.removeEventListener('mousedown', bounceIndicator)
    document.removeEventListener('mousemove', moveIndicator)
    document.removeEventListener('mouseup', debounceIndicator)
  }
}

function init() {
  if (document.getElementById('touch-indicator')) {
    console.warn('Cursor already initialized!')
    return
  }

  const indicator = document.createElement('div')
  indicator.setAttribute('id', 'touch-indicator')

  if (isTouchScreen()) {
    document.body.classList.add('iq-touch')
  } else {
    document.body.classList.add('iq-pointer')
  }

  unsubscribe = initCursor(indicator)
}

function deinit() {
  if (unsubscribe) {
    unsubscribe()
    unsubscribe = undefined
  }

  document.body.classList.remove('iq-touch')
  document.body.classList.remove('iq-pointer')
}

// noinspection JSUnusedGlobalSymbols
export default {
  init, deinit, isTouchScreen
}
