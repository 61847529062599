import React, { CSSProperties, FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { IconSpinner } from '../vector/Vector'
import cn from 'classnames'
import './view.scss'
import useIsMountedRef from '../../../hooks/useIsMountedRef'

export type IQViewState = 'loading' | 'empty' | 'normal' | Error

export type ErrorViewProps = {
  error: Error
}

export type ViewProps<A> = {
  className?: string
  style?: CSSProperties
  state?: 'initial' | IQViewState
  content: A | (() => Promise<A>)
  children: (data: A) => ReactNode
  errorView?: FunctionComponent<ErrorViewProps>
  loadingView?: FunctionComponent
  emptyView?: FunctionComponent
  onLoaded?: (data: A) => void
  onError?: (e: Error) => void
  onEmpty?: () => void
  isEmpty?: (data: A | any | null | undefined) => boolean
  isError?: (data: A | any | null | undefined) => boolean
}

const DefaultLoadingView = () => {
  return <div className='iq-loading-view'>
    <IconSpinner />
  </div>
}

const DefaultErrorView = ({ error }: ErrorViewProps) => {
  return <div className='iq-error-view'>{ error.message || error }</div>
}

const DefaultEmptyView = () => {
  return <div className='iq-empty-view'>
    Nothing to show
  </div>
}

const View = function <A>({
  content, children, className, style, state: forceState = 'initial',
  emptyView: Empty = DefaultEmptyView,
  errorView: Error = DefaultErrorView,
  loadingView: Loading = DefaultLoadingView,
  isEmpty = (value) => !value || (Array.isArray(value) && !value.length),
  onEmpty = () => {}, onError = () => {}, onLoaded = () => {},
}: ViewProps<A>) {
  const [state, setState] = useState<IQViewState>('loading')
  const [data, setData] = useState<A | undefined>(undefined)
  const isMounted = useIsMountedRef()

  useEffect(() => {
    setData(undefined)
    setState('loading')

    const apply = (value: A) => {
      if (isMounted.current) {
        setData(value)

        if (isEmpty(value)) {
          setState('empty')
          onEmpty()
        } else {
          setState('normal')
          onLoaded(value)
        }
      }
    }

    if (typeof content === 'function') {
      // @ts-ignore
      content().then(apply).catch((e: Error) => {
        setState(e)
        setData(undefined)
        onError(e)
      })
    } else {
      apply(content)
    }
  }, [content, isMounted])

  const renderState = () => {
    const s: IQViewState = (forceState === 'initial') ? state : forceState

    switch (s) {
      case 'empty':
        return <Empty />
      case 'loading':
        return <Loading />
      case 'normal':
        return children(data!)
      default:
        // TODO turn mind on
        return <Error error={s} />
    }
  }

  return <div className={cn('iq-view-content', className)} style={style}>
    { renderState() }
  </div>
}

export default View
