import React from 'react'
import { SVGComponent } from './types'

const IconExpandMore: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className={'iq-svg-icon capslock'}
  style={style}
>
  <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' />
  <path d='M0 0h24v24H0z' fill='none' />
</svg>)

export default IconExpandMore
