import React, {
  CSSProperties, Key,
  ReactNode, MouseEvent, TouchEvent, useRef, useEffect
} from 'react';
import cn from 'classnames'
import Input, { IQInputType } from '../../form/input/TypedInput'
import { IQInputElement, IQTheme } from '../../../../types'
import Button from '../../button/Button'
import { absorbEvent } from '../../../../utils/events'
import { onKeyClick } from './utils'
import { path } from 'ramda'
import BaseModal from '../BaseModal'

export type KeyboardKey = {
  key: Key
  title?: ReactNode
  action?: string
  className?: string
  onClick?: (e: MouseEvent | TouchEvent) => void
} | string

export type KeyboardContentProps<A> = {
  type?: IQInputType
  value?: A
  layout: KeyboardKey[][]
  interceptor?: (value: string) => string
  checkValidity?: (value?: A) => boolean
  isOpened?: boolean
  onChange?: (value?: A) => void
  stringify?: (value?: A) => string
  parse?: (value?: string) => A
}

export type KeyboardProps<A> = KeyboardContentProps<A> & {
  isOpened?: boolean
  className?: string
  style?: CSSProperties
  theme?: IQTheme
  title?: ReactNode
  persistent?: boolean
  onSubmit?: (value?: A) => void
  onClose?: () => void
  onShow?: () => void
}

export const KeyboardContent = function <A> ({
  type, layout, value, checkValidity, stringify, parse,
  interceptor = (val: string) => val, onChange, isOpened
}: KeyboardContentProps<A>) {
  const inputRef = useRef<IQInputElement<A>>(null)

  const onClickSomeKey = (e: MouseEvent | TouchEvent) => {
    absorbEvent(e)

    if (inputRef.current) {
      onKeyClick(inputRef.current, interceptor(path(['target', 'dataset', 'value'], e) || ''))
    }
  }

  const renderKey = (item: KeyboardKey) => {
    let key, title, action, cls, onClick

    if (typeof item === 'string') {
      key = title = action = item
    } else {
      key = item.key
      title = item.title || key
      action = (item.action || key) as string
      cls = item.className
      onClick = item.onClick
    }

    let val = (typeof title === 'string') ? interceptor(title) : title
    return <Button
      flat
      key={key}
      title={val}
      dataValue={action}
      className={cls}
      onClick={onClick || onClickSomeKey}
    />
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  })

  return <>
    <div className={'iq-keyboard-input'}>
      <Input<A>
        autoFocus={isOpened}
        customValue={false}
        name={'value'}
        type={type}
        inputRef={inputRef}
        value={value}
        checkValidity={checkValidity}
        stringify={stringify}
        parse={parse}
        onChange={onChange}
      />
    </div>

    {layout.map((row, i) => (<div
      key={i}
      className={'iq-keyboard-row'}
    >
      { row.map(renderKey) }
    </div>))}
  </>
}

export const BaseKeyboard = function <A>(props: KeyboardProps<A> & {
  stringify?: (value?: A) => string
  parse?: (value?: string) => A
}) {
  const {
    onSubmit = () => {},
    interceptor,
    stringify, parse,
    className, style, layout,
    value, checkValidity, type, onChange,
    ...modalProps
  } = props

  return <BaseModal
    content={true as any as A}
    onApply={(v) => {
      // @ts-ignore
      onSubmit(v.value)
    }}
    { ...modalProps }
    className={cn('iq-modal-keyboard', className)}
  >
    {() => <KeyboardContent
      isOpened={modalProps.isOpened}
      type={type}
      value={value}
      layout={layout}
      interceptor={interceptor}
      checkValidity={checkValidity}
      onChange={onChange}
      stringify={stringify}
      parse={parse}
    />}
  </BaseModal>
}

BaseKeyboard.show = function <A> ({
  type, layout, value, checkValidity, stringify, parse,
  interceptor, onChange, className, onSubmit = () => {},
  onShow = () => {}, ...props
}: Partial<KeyboardProps<A>> & {
  onShow?: (update: (props: any) => void) => void
}) {
  return BaseModal.show({
    ...props,
    content: true as any as A,
    className: cn('iq-modal-keyboard', className),
    children: (_, resolve, __, update) => {
      onShow(update)

      return () => [<KeyboardContent<A>
        isOpened={true}
        key='keyboard'
        type={type}
        value={value}
        layout={layout!}
        checkValidity={checkValidity}
        stringify={stringify}
        parse={parse}
        interceptor={interceptor}
        onChange={onChange}
      />,
        {
          onApply: (v: { value: A }) => resolve(v.value)
        }
      ]
    }
  })
}

export default BaseKeyboard
