import React, { CSSProperties, MouseEvent, ReactNode, useEffect } from 'react'
import { Layout, Content } from './Layout'
import { messagesContainer } from '../message/Message'
import cn from 'classnames'
import './window.scss'
import Navigation, { NavigationRoute } from '../../navigation/Navigation'
import { IQDirection, IQTheme } from '../../../types'

export type WindowProps = {
  children: ReactNode
  className?: string
  style?: CSSProperties
  showWindowControls?: boolean
  focused?: boolean
  closeWindow?: (e: MouseEvent<HTMLDivElement>) => void
  minimizeWindow?: (e: MouseEvent<HTMLDivElement>) => void
  direction?: IQDirection
  route?: string
  nav?: NavigationRoute[]
  onNavigate?: (route: string) => void
  navMode?: 'auto' | 'compact' | 'floating' | 'default'
  footer?: ReactNode
  header?: ReactNode
  theme?: IQTheme
}

function Window(props: WindowProps) {
  const {
    className, style, children, showWindowControls, focused,
    nav, onNavigate, route, header,
    closeWindow, minimizeWindow, footer,
    theme = 'dark'
  } = props

  useEffect(() => {
    if (showWindowControls) {
      document.body.classList.add('iq-window-with-header')
      messagesContainer.style.top = '26px'
    } else {
      document.body.classList.remove('iq-window-with-header')
      messagesContainer.style.top = '0px'
    }
  }, [showWindowControls])

  return <div className={cn('iq-window', `iq-${theme}`, {focused: focused !== false})}>
    { showWindowControls && <div className='iq-window-header'>
      <span>{ document.title }</span>
      {!!closeWindow && <div className='iq-window-action-close' onClick={closeWindow} />}
      {!!closeWindow && <div className='iq-window-action-minimize' onClick={minimizeWindow} />}
    </div> }

    <div className='iq-window-content'>
      { header }
      <Layout>
        { nav && <Navigation
          route={route}
          routes={nav}
          onNavigate={onNavigate}
        /> }

        <Content className={className} style={style}>
          { children }
        </Content>
      </Layout>

      { footer }
    </div>
  </div>
}

export default Window
