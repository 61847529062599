import React, {
  ReactNode, Key, FormEvent, useState, useEffect, useRef,
  useLayoutEffect, CSSProperties, Ref
} from 'react';
import cn from 'classnames'
import { isMobile } from 'is-mobile'
import { emitInputChanged } from '../../modal/keyboard/utils'
import Button from '../../button/Button'
import { IQInputElement } from '../../../../types'
import './input.scss'

export interface InputAction {
  key: Key
  type?: string
  title: ReactNode
  disabled?: boolean
  onClick: (event: React.MouseEvent | React.TouchEvent) => void
}

export interface BaseInputProps<A> {
  type?: string
  name: string
  value?: A
  className?: string
  style?: CSSProperties
  hint?: ReactNode | ((value?: A, inputValue?: string) => ReactNode)
  actions?: InputAction[]
  placeholder?: string
  readOnly?: boolean
  autoFocus?: boolean
  hidden?: boolean
  onChange?: (value?: A) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  checkValidity?: (value?: A) => boolean
  disabled?: boolean,
  required?: boolean
}

const BaseInput = function <A>(
  props: BaseInputProps<A> & {
    customValue: boolean
    stringify: (value?: A) => string
    parse: (value?: string) => A | undefined
    inputRef?: Ref<IQInputElement<A>>
  },
) {
  const {
    className, style, placeholder, name, type = 'text', autoFocus,
    hint, actions = [], value: defaultValue, onChange = () => {}, readOnly,
    checkValidity = () => true, onFocus, onBlur, onClick, customValue, disabled,
    stringify, parse, required,
    hidden, inputRef: ref
  } = props

  const inputRef = useRef<IQInputElement<A>>(null)
  const [value, setValue] = useState<string>(stringify(defaultValue) || '')
  const [dirty, setDirty] = useState(false)

  const checkInput = (val?: string) => {
    if (required && !value) {
      return false
    }

    try {
      return checkValidity(parse(val))
    } catch (e) {
      return false
    }
  }

  useLayoutEffect(
    () => {
      const el: Partial<IQInputElement<A>> | null = inputRef.current
      if (el) {
        el.isValid = () => {
          setDirty(true)
          return checkInput(value)
        }

        el.isDirty = () => dirty

        if (!customValue) {
          el.getValue = () => {
            try {
              return parse(value)
            } catch (e) {
              return undefined
            }
          }

          el.setValue = (val: A, focus = true) => {
            const str = stringify(val)
            setValue(str)
            onChange(val)
            emitInputChanged(el as HTMLInputElement, str, str.length, focus)
          }
        }
      }

      if (ref) {
        if (typeof ref === 'function') {
          ref(el as IQInputElement<A>)
        } else {
          (ref as any).current = el
        }
      }
    },
    [inputRef, ref, value, dirty]
  )

  useEffect(
    () => {
      if (autoFocus && inputRef.current) {
        inputRef.current.focus()
      }
    },
    [autoFocus, inputRef]
  )

  const onInput = (e: FormEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    setValue(val)

    setDirty(true)
    try {
      onChange(parse(val))
    } catch (e) {
    }
  }

  useEffect(
    () => {
      try {
        if (defaultValue !== parse(value)) {
          setValue(stringify(defaultValue))
        }
      } catch (e) {
      }
    },
    [defaultValue]
  )

  const renderHint = () => {
    if (!hint) {
      return false
    }

    if (typeof hint === 'function') {
      let val
      try {
        val = parse(value)
      } catch (e) {
      }

      return <span className='hint'>{ hint(val, value) }</span>
    }

    return <span className='hint'>{ hint }</span>
  }

  return <div
    style={style}
    className={cn('iq-input', className, {
      invalid: dirty ? !checkInput(value) : false,
      disabled, hidden, mobile: isMobile()
    })}
  >
    <input
      ref={inputRef}
      type={type === 'number' ? 'tel' : type}
      name={name}
      required
      formNoValidate
      autoComplete='off'
      placeholder={placeholder}
      onInput={onInput}
      onChange={() => {}}
      onFocus={onFocus}
      onClick={onClick}
      onBlur={onBlur}
      value={value}
      hidden={hidden}
      disabled={disabled || hidden}
      readOnly={readOnly || (isMobile() && actions.filter((a) => a.type === 'internal_action').length === 0)}
    />

    <span className='bar' />
    { renderHint() }
    {placeholder && <label htmlFor={name}>{ placeholder }</label>}

    <div style={{ display: 'flex' }} onClick={() => inputRef.current && inputRef.current.focus()}>
      { actions.map(({ key, onClick: onActionClick, title, disabled: d }) => <Button
        flat
        key={ key }
        title={ title }
        disabled={ d }
        onClick={ onActionClick }
        className='iq-input-action'
      />) }
    </div>
  </div>
}

export default BaseInput
