import React, {
  Key, ReactNode,
  MouseEvent, TouchEvent, useRef, CSSProperties, useMemo,
} from 'react'
import * as ReactDOM from 'react-dom'
import cn from 'classnames'
// FIXME deprecated with react-motion!
import Mortal from './Mortal'
import { IQTheme } from '../../../types'
import Button, { ButtonProps } from '../button/Button'
import Form from '../form/Form'
import View, { ErrorViewProps, ViewProps } from '../layout/View'
import { onSubmitClick } from './keyboard/utils'

export function mountModalToDOM<A>(
  ModalComponent: typeof BaseModal,
  props: any & {
    content: A | (() => Promise<A>)
    children: (data: A) => ReactNode
  }
) {
  const div = document.createElement('div')
  document.body.appendChild(div)

  const update = (updatedProps: any & {
    content: A | (() => Promise<A>)
    children: (data: A) => ReactNode
  }) => {
    const { children, content, ...modalProps } = updatedProps

    ReactDOM.render(<ModalComponent content={content!} {...modalProps} isOpened={true}>
      { children! }
    </ModalComponent>, div)
  }

  const mount = () => {
    const { children, content, ...modalProps } = props

    ReactDOM.render(
      <ModalComponent{...modalProps} isOpened={false} content={content!}>
        { children! }
      </ModalComponent>,
      div,
      () => {
        ReactDOM.render(<ModalComponent content={content!} {...modalProps} isOpened={true}>
          { children! }
        </ModalComponent>, div)
      }
    )
  }

  const unmount = () => {
    const { children, content, ...modalProps } = props

    ReactDOM.render(<ModalComponent content={content!} {...modalProps} isOpened={false}>
      { children! }
    </ModalComponent>, div)
    setTimeout(
      () => {
        const unmountResult = ReactDOM.unmountComponentAtNode(div)
        if (unmountResult && div.parentNode) {
          div.parentNode.removeChild(div)
        }
      },
      1000
    )
  }

  mount()
  return { unmount, update }
}

export type ModalFooterAction = {
  key: Key
  title: ReactNode
  submit?: boolean
  onClick?: (event: MouseEvent | TouchEvent) => void
} & Partial<ButtonProps>

export type ModalProps<A> = {
  theme?: IQTheme
  title?: ReactNode
  isOpened?: boolean
  footerActions?: ModalFooterAction[]
  onClose?: () => void
  onShow?: () => void
  onCancel?: () => void
  onApply?: (value?: A) => void
  persistent?: boolean
  fullscreen?: boolean

  className?: string
  style?: CSSProperties
  state?: ViewProps<A>['state']
  content?: A | (() => Promise<A>)
  children: (data: A) => ReactNode
  onLoaded?: (data: A) => void
  onError?: (e: Error) => void
  onEmpty?: () => void
  isEmpty?: (data: A | any | null | undefined) => boolean
  isError?: (data: A | any | null | undefined) => boolean
  errorView?: ReactNode
  emptyView?: ReactNode
}

const BaseModal = function <A>(props: ModalProps<A>) {
  const {
    isOpened = false, className, theme, errorView, emptyView,
    content = true as any as A, fullscreen,
    title, footerActions = [], persistent,
    onClose = () => {}, onShow = () => {}, onApply = () => {}, onCancel = () => {},
    children = (node: A) => node, ...viewProps
  } = props

  const formRef = useRef<HTMLFormElement>(null)

  function footer() {
    if (footerActions.length === 0) {
      return false
    }

    return <div className='iq-modal-footer'>
      { footerActions.map(({ key, title: actionTitle, submit, onClick, ...buttonProps }) => <Button
        { ...buttonProps }
        flat
        key={ key }
        title={ actionTitle }
        submit={submit}
        onClick={(e) => {
          if (typeof onClick === 'function') {
            onClick(e)
          }

          if (!persistent) {
            onClose()
          }
        }}
      />) }
    </div>
  }

  const precision = useMemo(() => document.body.classList.contains('iq-weak') ? 100 : 0.1, [])

  return <Mortal
    isOpened={ isOpened }
    onClose={() => {
      onClose()
      onCancel()
    }}
    onShow={ onShow }
    onSubmit={() => formRef.current && onSubmitClick(formRef.current)}
    closeOnEsc={!persistent}
    motionStyle={ (spring, isVisible) => ({
      opacity: spring(isVisible ? 1 : 0, {precision}),
      modalOffset: spring(isVisible ? 0 : -90, {
        precision,
        stiffness: isVisible ? 500 : 150,
        damping: isVisible ? 50 : 10,
      })
    }) }
  >
    { (motion, isVisible) => (<div
      className='iq-modal-overlay'
      style={{
        opacity: motion.opacity,
        pointerEvents: isVisible ? 'auto' : 'none',
      }}
      onClick={() => {
        if (!persistent) {
          onCancel()
          onClose()
        }
      }}
    >
      <div
        onClick={ (e) => e.stopPropagation() }
        className={ cn('iq-modal', className, { fullscreen }, theme && `iq-${theme}`) }
        style={{
          opacity: motion.opacity,
          WebkitTransform: `translate3d(0, ${motion.modalOffset}px, 0)`,
          transform: `translate3d(0, ${motion.modalOffset}px, 0)`,
        }}
      >
        <View
          content={content}
          {...viewProps}
          errorView={ ({ error }: ErrorViewProps) => {
            return <div className='iq-error-view'>
              <div className='iq-action-modal-cancel'>
                <div className='iq-action-modal-cancel-message'>
                  { errorView || error.message || error }
                </div>
                <Button
                  flat
                  theme='dark'
                  title={'OK'}
                  onClick={() => {
                    onCancel()
                    onClose()
                  }}
                />
              </div>
            </div>
          }}
          emptyView={ () => {
            return <div className='iq-empty-view'>
              <div className='iq-action-modal-cancel'>
                <div className='iq-action-modal-cancel-message'>
                  { emptyView || <span>Nothing to show</span>}
                </div>
                <Button
                  flat
                  theme={theme}
                  title={'OK'}
                  onClick={() => {
                    onCancel()
                    onClose()
                  }}
                />
              </div>
            </div>
          }}
        >
          {(data) => {
            return <Form
              formRef={formRef}
              onSubmit={(value) => {
                onApply(value as A)
                onClose()
              }}
            >
              { title && <div className='iq-modal-header'>
                <h6>{ title }</h6>
              </div> }
              <div className='iq-modal-content-wrapper'>
                <div className='iq-overflow-container'>
                  <div className='iq-modal-content'>
                    { children(data) }
                  </div>
                </div>
              </div>
              { footer() }
            </Form>
          } }
        </View>
      </div>
    </div>) }
  </Mortal>
}

type ModalCallbacks<A> = {
  onClose?: () => void
  onCancel?: () => void
  onApply?: (value?: A) => void
  onFooterActionClick?: (key: Key, event: MouseEvent | TouchEvent) => void
  update?: (props: any) => void
}

export type ModalPromiseProps<A> = {
  theme?: IQTheme
  title?: ReactNode
  footerActions?: Partial<ModalFooterAction> & {
    key: Key
    title: ReactNode
  }[]
  fullscreen?: boolean
  persistent?: boolean
  className?: string
  style?: CSSProperties
  content?: A | (() => Promise<A>)
  onShow?: () => void
  onLoaded?: (data: A) => void
  onError?: (e: Error) => void
  onEmpty?: () => void
  isEmpty?: (data: A | any | null | undefined) => boolean
  isError?: (data: A | any | null | undefined) => boolean
  errorView?: ReactNode
  emptyView?: ReactNode
  children: (
    data: A,
    resolve: (value?: A) => void,
    reject: (reason?: A | any) => void,
    update: (props: any) => void
  ) => ReactNode | (() => [ReactNode, ModalCallbacks<A>])
}

function makeModalProps<A>(
  properties: ModalPromiseProps<A>,
  resolve: (value?: A) => void,
  reject: (reason?: A | any) => void,
  callbacks: ModalCallbacks<A>
) {
  const {
    content = true as any as A,
      footerActions = [],
      children, ...props
  } = properties

  return {
    ...props,
    content,
    footerActions: footerActions.map((action: Partial<ModalFooterAction>) => {
    return {
      ...action,
      onClick: (e) => {
        if (callbacks.onFooterActionClick) {
          callbacks.onFooterActionClick(action.key!, e)
        }
      }
    } as ModalFooterAction
  }),
    onClose: () => callbacks.onClose && callbacks.onClose(),
    onCancel: () => callbacks.onCancel && callbacks.onCancel(),
    onApply: (data?: A) => callbacks.onApply && callbacks.onApply(data),
    children: (data: A) => {
      const ret = children(data, resolve, reject, (newProps: any) => {
        callbacks.update!(makeModalProps(newProps, resolve, reject, callbacks))
      })

      if (typeof ret === 'function') {
        const [node, cb = {}] = ret()

        for (let key of Object.keys(cb)) {
          callbacks[key] = cb[key]
        }

        return node
      } else {
        return ret
      }
    },
    isOpened: true,
  }
}

BaseModal.show = function <A>(props: ModalPromiseProps<A>) {
  return new Promise<A>(((resolve, reject) => {
    const _resolve = (value?: A) => {
      resolve(value)
      unmount()
    }

    const _reject = (reason?: A | any) => {
      reject(reason)
      unmount()
    }

    const cb: ModalCallbacks<A> = {
      onApply: _resolve,
      onCancel: _reject
    }

    const { unmount, update } = mountModalToDOM<A>(BaseModal, makeModalProps(props, _resolve, _reject, cb))

    cb.update = update
  }))
}

export default BaseModal
