import React from 'react'
import { SVGComponent } from './types'

const IconSpinner: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='50'
  height='50'
  viewBox='0 0 50 50'
  className={'iq-svg-icon spinner'}
  style={style}
>
  <circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='5' />
</svg>)

export default IconSpinner
