import React, {
  Key, ReactNode, CSSProperties, useState, useEffect,
  useCallback, useMemo
} from 'react';
import cn from 'classnames'
import uuid from 'uuid'
import { without, append, findIndex, equals, find, path, last } from 'ramda'
import Button from '../button/Button'
import { IconCheckbox, IconRadio } from '../vector/Vector'
import AutoFit from '../typography/AutoFit'
import { absorbEvent } from '../../../utils/events'
import './list.scss'

export interface ItemProps {
  key: Key
  title: ReactNode
  subtitle?: ReactNode
  avatar?: ReactNode
  icon?: ReactNode
  disabled?: boolean
  focusable?: boolean
  className?: string
}

export type ListSelectionMode = 'single' | 'multiple' | 'sequence' | 'item' | 'none'

export interface ListProps<A> {
  items: A[]
  selected?: A | A[]
  render: (item: A, index: number, items: A[]) => ItemProps
  style?: CSSProperties
  className?: string
  selectionMode?: ListSelectionMode
  onSelect?: (selected: A | A[], index: number, items: A[]) => void
  onFocus?: (selected: A | A[], index: number, items: A[]) => void
}

const getItemIcon = (item: ItemProps, selectionIndex: number, selectionMode: ListSelectionMode) => {
  switch (selectionMode) {
    case 'none':
    case 'item':
      return item.icon
    case 'single':
      if (selectionIndex === -1) {
        return <IconRadio.Unchecked />
      } else {
        return <IconRadio.Checked />
      }
    default:
      if (selectionIndex === -1) {
        return <IconCheckbox.Blank />
      }

      if (selectionMode === 'multiple') {
        return <IconCheckbox.Checked />
      } else {
        return <span className='iq-icon-selection-sequence'>
          <AutoFit mode='reduce'>{ selectionIndex + 1 }</AutoFit>
        </span>
      }
  }
}

const Item = (props: ItemProps & {
  selected: boolean
  onClick: () => void
  onFocus: () => void
  tabIndex: number
  id: string
}) => {
  const {
    title, avatar, subtitle, icon, ...buttonProps
  } = props

  return <Button
    flat
    textAlign='left'
    {...buttonProps}
  >
    <div className='iq-item-content-wrapper'>
      <div className='iq-item-avatar'>
        { avatar }
      </div>
      <div className='iq-item-content'>
        <div className='iq-item-title'>{ title }</div>
        <div className='iq-item-subtitle'>{ subtitle }</div>
      </div>
      { icon && <div className='iq-item-extra'>
        <div className='iq-item-icon'>{ icon }</div>
      </div> }
    </div>
  </Button>
}

function navigate(listId: string, id?: string | number, up?: boolean) {
  if (id === undefined || (typeof id === 'string' && !id.startsWith(listId))) {
    return
  }

  const findNextElement = (i: number, step: number): HTMLElement => {
    const el = document.getElementById(`${listId}${i + step}`)
    // @ts-ignore
    if (el && el.disabled) {
      return findNextElement(i + step, step)
    }

    return el as HTMLElement
  }

  const index = typeof id === 'string' ? Number.parseInt(id.slice(listId.length), 10) : id
  const nextEl = findNextElement(index, up ? -1 : 1)
  return nextEl && nextEl.focus()
}

const List = function <A>(props: ListProps<A>) {
  const {
    items, selected, onSelect = () => {}, style,
    className, selectionMode = 'none', render, onFocus = () => {}
  } = props

  const [selectedItems, setSelected] = useState<A[]>([])

  const listId = useMemo(uuid, [])

  useEffect(() => {
    switch (selectionMode) {
      case 'single':
      case 'item':
        setSelected([selected as A])
        break;
      case 'multiple':
      case 'sequence':
        if (!selected) {
          setSelected([])
        } else {
          setSelected(selected as A[])
        }
        break;
      default:
        return;
    }
  }, [selected, selectionMode])

  const select = useCallback((item: A, index: number) => {
    switch (selectionMode) {
      case 'none':
        onSelect(item, index, items)
        return;
      case 'single':
      case 'item':
        setSelected([item])
        onSelect(item, index, items)
        return;
      case 'multiple':
      case 'sequence':
        const newSelection = find(equals(items[index]), selectedItems) ?
          without([item], selectedItems) :
          append(item, selectedItems)

        setSelected(newSelection)
        onSelect(newSelection, index, items)
        return;
      default:
        return;
    }
  }, [selectionMode, items, selectedItems, onSelect])

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'ArrowDown':
          absorbEvent(e)
          navigate(listId, path(['target', 'id'], e))
          return
        case 'ArrowUp':
          absorbEvent(e)
          navigate(listId, path(['target', 'id'], e), true)
          return
        default:
          return
      }
    }

    window.addEventListener('keydown', handleKey)
    navigate(listId, -1, false)

    return () => {
      window.removeEventListener('keydown', handleKey)
    }
  }, [])

  useEffect(() => {
    const item = last(selectedItems)
    const index = findIndex(equals(item), items)
    if (index >= 0) {
      navigate(listId, index - 1, false)
    }

  }, [selectedItems, items])

  return <div className={cn('iq-list', className)} style={style}>
    { items.map((raw, index) => {
      const selectedIndex = findIndex(equals(items[index]), selectedItems)
      const item = render(raw, index, items)

      return <Item
        { ...item }
        tabIndex={0}
        id={`${listId}${index}`}
        key={ item.key }
        selected={selectionMode === 'item' && selectedIndex !== -1}
        icon={getItemIcon(item, selectedIndex, selectionMode)}
        onClick={() => select(items[index], index)}
        onFocus={() => onFocus(items[index], index, items)}
      />
    }) }
  </div>
}

export default List
