import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import useResize from '../../../hooks/useResize'

export type IQAutoFitProps = {
  children: React.ReactChild
  className?: string
  style?: CSSProperties
  mode?: 'increase' | 'reduce' | 'auto'
}

// TODO use font observer
function AutoFit({ mode, children, ...props }: IQAutoFitProps) {
  const { ref: parentRef, width, height } = useResize<HTMLDivElement>()
  const [fontSize, setFontSize] = useState(16)
  const childrenRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (width === null || height === null) {
      return
    }

    const cw = childrenRef.current!.offsetWidth
    const ch = childrenRef.current!.offsetHeight

    if (cw === 0) {
      setFontSize(0)
      return
    }

    const calculatedSize = Math.floor(width / cw * ch * fontSize / ch * 0.98)

    const parentFontSize = parseFloat(
      window
        .getComputedStyle(parentRef.current!, null)
        .getPropertyValue('font-size')
    )

    switch (mode) {
      case 'increase':
        setFontSize(Math.max(parentFontSize, calculatedSize))
        break
      case 'reduce':
        setFontSize(Math.min(parentFontSize, calculatedSize))
        break
      default:
        setFontSize(calculatedSize)
        break
    }
  }, [width, children])

  return <div
    ref={parentRef}
    className={cn('iq-auto-fit-parent', props.className)}
    style={props.style}
  >
    <div className='iq-auto-fit-spacer' style={{ fontSize }}>&nbsp;</div>
    <div
      ref={childrenRef}
      className='iq-auto-fit-children'
      style={{ fontSize }}
    >
      { children }
    </div>
  </div>
}

export default AutoFit
