import { useEffect, useState, useRef, useMemo } from 'react'

export type IQElementSize = {
  width: number | null
  height: number | null
  prevWidth: number | null
  prevHeight: number | null
}

function useResize<T extends Element>() {
  const ref = useRef<T>(null)
  const [size, setSize] = useState<IQElementSize>({
    width: null,
    height: null,
    prevWidth: null,
    prevHeight: null,
  })
  const previous = useRef<Partial<IQElementSize>>({ width: null, height: null })

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const element = ref.current
    const resizeObserver = new ResizeObserver(entries => {
      if (!Array.isArray(entries) || !entries.length) {
        return
      }

      const entry = entries[0]
      const newWidth = Math.round(entry.contentRect.width)
      const newHeight = Math.round(entry.contentRect.height)

      if (
        previous.current.width !== newWidth ||
        previous.current.height !== newHeight
      ) {
        setSize({
          width: newWidth,
          height: newHeight,
          prevWidth: previous.current.width!,
          prevHeight: previous.current.height!
        })

        previous.current.width = newWidth
        previous.current.height = newHeight
      }
    })

    resizeObserver.observe(element)
    return () => resizeObserver.unobserve(element)
  }, [ref])

  return useMemo(() => ({ ref, ...size }), [
    ref,
    size.width,
    size.height,
  ])
}

export default useResize
