import React from 'react'
import { SVGComponent } from './types'

const IconArrowForward: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className={ 'iq-svg-icon arrow-forward' }
  style={style}
>
  <path d='M0 0h24v24H0z' fill='none'/>
  <path d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z'/>
</svg>)

export default IconArrowForward
