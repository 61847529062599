import React from 'react'
import { SVGComponent } from './types'

const IconMenu: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className={'iq-svg-icon backspace'}
  style={style}
>
  {/* tslint:disable-next-line */}
  <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
  <path d='M0 0h24v24H0z' fill='none'/>
</svg>)

export default IconMenu
