import React from 'react'
import { SVGComponent } from './types'

const IqLogo: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='385'
  height='399'
  viewBox='0 0 385 399'
  className={ 'iq-svg-icon iq-logo' }
  style={style}
>
  <g>
    { /* tslint:disable-next-line */ }
    <path d='M269.226609,332.037863 C317.838597,305.016256 350.735,253.113248 350.735,193.522 C350.735,106.071 279.891,35.178 192.5,35.178 C105.109,35.178 34.265,106.071 34.265,193.522 C34.265,280.972 105.109,351.865 192.5,351.865 C206.692991,351.865 220.449533,349.995103 233.537032,346.488068 L225.85,327.446 L261.548,313.016 L269.226609,332.037863 Z M282.143439,364.036129 L290.388,384.46 L254.691,398.89 L246.453832,378.485152 C229.334775,383.477238 211.229772,386.154 192.5,386.154 C86.1852,386.154 0,299.909 0,193.522 C0,87.134 86.1852,0.89 192.5,0.89 C298.815,0.89 385,87.134 385,193.522 C385,267.518589 343.305577,331.770918 282.143439,364.036129 Z' />
    <polygon points='201.355 185.046 227.15 279.821 184.415 201.997 107.03 156.922' />
  </g>
</svg>)

export default IqLogo
