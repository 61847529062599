import BaseKeyboard from './BaseKeyboard'
import KeyboardIP from './KeyboardIP'
import KeyboardText from './KeyboardText'
import KeyboardNumber from './KeyboardNumber'
import './keyboard.scss'

type TKeyboard = typeof BaseKeyboard & {
  IP: typeof KeyboardIP
  Text: typeof KeyboardText
  Number: typeof KeyboardNumber
}

const Keyboard: any = BaseKeyboard
Keyboard.IP = KeyboardIP
Keyboard.Text = KeyboardText
Keyboard.Number = KeyboardNumber

export default Keyboard as TKeyboard
