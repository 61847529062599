import React, { useState, useEffect } from 'react'
import { Portal, PortalProps } from 'react-portal'
import { Motion, spring, PlainStyle, SpringHelperConfig, OpaqueConfig, Style } from 'react-motion'

export type IQMortalProps = {
  onClose: Function,
  onSubmit?: Function,
  onHide?: Function,
  onShow?: Function,
  portalProps?: PortalProps,
  closeOnEsc?: boolean,
  isOpened: boolean,
  children: (
    motion: PlainStyle,
    isVisible: boolean,
    isAnimated: boolean
  ) => JSX.Element,
  motionStyle: (
    spring: (val: number, config?: SpringHelperConfig) => OpaqueConfig,
    isVisible: boolean
  ) => Style
}

function Mortal(props: IQMortalProps) {
  const {
    portalProps, children, closeOnEsc, motionStyle, isOpened,
    onClose, onSubmit = () => {}, onHide = () => {}, onShow = () => {},
  } = props

  const [isPortalOpened, setPortalOpened] = useState(false)
  const [isAnimated, setAnimated] = useState(false)
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    const handleKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && closeOnEsc !== false) {
        onClose()
      }

      if (e.key === 'Enter') {
        onSubmit()
        e.preventDefault()
      }
    }

    if (isOpened) {
      window.addEventListener('keydown', handleKey)

      return () => {
        window.removeEventListener('keydown', handleKey)
      }
    } else {
      return () => {}
    }
  }, [closeOnEsc, isOpened])

  useEffect(() => {
    setAnimated(true)

    if (!isPortalOpened && isOpened) {
      setPortalOpened(true)
    } else if (isPortalOpened && !isOpened) {
      setVisible(false)
    }

    const request = window.requestAnimationFrame(() => setVisible(isOpened))
    return () => window.cancelAnimationFrame(request)
  }, [isOpened])

  useEffect(() => {
    if (isVisible) {
      onShow()
    }
  }, [isVisible])

  const handleRest = () => {
    if (!isOpened) {
      setPortalOpened(false)
      setAnimated(false)
      onHide()
    } else {
      setAnimated(false)
    }
  }

  if (!isPortalOpened) {
    return null
  }

  return (
    <Portal {...portalProps}>
      <Motion onRest={handleRest} style={motionStyle(spring, isVisible)}>
        {(motion: PlainStyle) => children(motion, isVisible, isAnimated)}
      </Motion>
    </Portal>
  )
}

export default Mortal
