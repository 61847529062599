import React, { useState, useEffect, CSSProperties, ReactNode } from 'react'
import { endsWith } from 'ramda'
import cn from 'classnames'
import Keyboard, { KeyboardContent, KeyboardKey } from './BaseKeyboard'
import {
  Lang, ShiftFn, SetLangFn, keyBackspace, keySend, keyShift,
  keySpaceBar, keySwitchLanguage
} from './keys'
import { IQTheme } from '../../../../types'
import BaseModal, { ModalFooterAction, ModalPromiseProps } from '../BaseModal'

const header = ['@', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

const footer = (lang: Lang, setLang: SetLangFn) => [
  '?', '!', ',',
  keySwitchLanguage(lang, setLang),
  keySpaceBar(lang),
  '.',
  keySend
]

const en = (shiftPressed: boolean, setShiftPressed: ShiftFn) => {
  return [
    ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    [
      keyShift(shiftPressed, setShiftPressed, 'x2'),
      'Z', 'X', 'C', 'V', 'B', 'N', 'M',
      {
        ...keyBackspace,
        className: 'x2'
      }
    ],
  ]
}

const ru = (shiftPressed: boolean, setShiftPressed: ShiftFn) => [
  ['Й', 'Ц', 'У', 'К', 'Е', 'Н', 'Г', 'Ш', 'Щ', 'З', 'Х'],
  ['Ф', 'Ы', 'В', 'А', 'П', 'Р', 'О', 'Л', 'Д', 'Ж', 'Э'],
  [keyShift(shiftPressed, setShiftPressed), 'Я', 'Ч', 'С', 'М', 'И', 'Т', 'Ь', 'Б', 'Ю', {
    ...keyBackspace,
    className: 'x1'
  }],
]

const isShiftShouldBePressed = (val: string = '', type: 'text' | 'password') => {
  if (type === 'password') {
    return false
  }

  const str = val.trim()
  return str.length === 0 ||
    endsWith('.', str) ||
    endsWith('. ', str) ||
    endsWith('?', str) ||
    endsWith('!', str)
}

const inputInterceptor = (val: string, shiftPressed: boolean) => {
  if (val.length === 1) {
    if (shiftPressed) {
      return val.toLocaleUpperCase()
    } else {
      return val.toLocaleLowerCase()
    }
  }

  return val
}

const getLayout = (
  lang: Lang, setLang: SetLangFn,
  shiftPressed: boolean, setShiftPressed: ShiftFn
) => {
  if (lang === 'ru') {
    return [
      header,
      ...ru(shiftPressed, setShiftPressed),
      footer(lang, setLang)
    ]
  } else {
    return [header, ...en(shiftPressed, setShiftPressed), footer(lang, setLang)]
  }
}

export interface KeyboardTextProps {
  isOpened: boolean
  theme?: IQTheme
  value?: string
  className?: string
  style?: CSSProperties
  language?: Lang
  type?: 'text' | 'password'
  checkValidity?: (value?: string) => boolean
  onSubmit?: (value?: string) => void
  onChange?: (value?: string) => void
  title?: ReactNode
  persistent?: boolean
  footerActions?: ModalFooterAction[]
  onClose?: () => void
  onShow?: () => void
}

const KeyboardText = (props: KeyboardTextProps) => {
  const { language = 'en', type = 'text', ...keyboardProps } = props

  const [lang, setLang] = useState<Lang>(language)
  const [layout, setLayout] = useState<KeyboardKey[][]>([])
  const [shiftPressed, setShiftPressed] = useState<boolean>(type !== 'password')

  useEffect(() => setLayout(getLayout(lang, setLang, shiftPressed, setShiftPressed)), [shiftPressed, lang])

  return <Keyboard
    { ...keyboardProps }
    type={type}
    onChange={(val) => {
      setShiftPressed(isShiftShouldBePressed(val, type))
    }}
    interceptor={(val) => inputInterceptor(val, shiftPressed)}
    layout={layout}
  />
}

KeyboardText.show = function ({
  type = 'text', value, checkValidity, className, onSubmit = () => {},
  onShow = () => {}, language, ...props
}: Partial<KeyboardTextProps> & {
  onShow?: (update: (props: any) => void) => void
}) {
  let lang = language || 'en'
  let shiftPressed = type !== 'password'
  const cb = {
    setLang: (lng: Lang) => {
      if (lang === lng) {
        return
      }

      lang = lng
      cb.update(makeModalProps())
    },
    setShiftPressed: (pressed: boolean) => {
      if (shiftPressed === pressed) {
        return
      }

      shiftPressed = pressed
      cb.update(makeModalProps())
    },
    update: ((() => {}) as (props: any) => void)
  }

  const makeModalProps = () => ({
    ...props,
    content: 'true',
    className: cn('iq-modal-keyboard', className),
    children: (_, resolve, __, update) => {
      cb.update = update

      return () => [<KeyboardContent<string>
        key='keyboard'
        type={type}
        value={value}
        layout={getLayout(lang, cb.setLang, shiftPressed, cb.setShiftPressed)!}
        checkValidity={checkValidity}
        interceptor={(val) => inputInterceptor(val, shiftPressed)}
        onChange={(val) => cb.setShiftPressed(isShiftShouldBePressed(val, type))}
      />,
        {
          onApply: (v: { value: string }) => resolve(v.value)
        }
      ]
    }
  } as ModalPromiseProps<string>)

  return BaseModal.show(makeModalProps())
}

export default KeyboardText
