import React from 'react'
import { SVGComponent } from './types'

const IconChevron: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className='iq-svg-icon chevron'
  style={style}
>
  <path d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z' />
  <path d='M0 0h24v24H0z' fill='none' />
</svg>)

export default IconChevron
