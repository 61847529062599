import React, { useRef, useLayoutEffect, FormEvent, ReactNode, Ref } from 'react'
import cn from 'classnames'
import { assocPath } from 'ramda'
import { DomNode, IQDirection, IQInputElement } from '../../../types'
import { absorbEvent } from '../../../utils/events'

export interface BaseFormProps<A extends { [key: string]: any }> extends DomNode {
  direction?: IQDirection
  children?: ReactNode
  onSubmit: (data: A) => void
}

function getFromValues<A>(elements: HTMLFormControlsCollection, validate: boolean = false) {
  if (!elements || elements.length === 0) {
    return undefined
  }

  let hasErrors = false
  let out = {}

  for (let i = 0; i < elements.length; i++) {
    const el = elements[i]

    if (el.nodeName === 'INPUT') {
      const input = el as IQInputElement<A>
      if (validate) {
        const valid = input.isValid()
        hasErrors = hasErrors || !valid
      }

      out = assocPath(input.name.split('.'), input.getValue(), out)
    }
  }

  if (!hasErrors) {
    return out
  } else {
    return undefined
  }
}

const BaseForm = function <A>(props: BaseFormProps<A> & {
  formRef?: Ref<HTMLFormElement>
}) {
  const {
    className, onSubmit, children,
    direction = 'vertical', formRef: ref,
    ...otherProps
  } = props

  const formRef = useRef<HTMLFormElement>(null)

  useLayoutEffect(
    () => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(formRef.current)
        } else {
          (ref as any).current = formRef.current
        }
      }
    },
    [formRef]
  )

  const getData = (elements: HTMLFormControlsCollection, validate?: boolean): A | undefined => {
    return getFromValues(elements, validate) as A
  }

  const onFormSubmit = (e: FormEvent & { currentTarget: HTMLFormElement }) => {
    absorbEvent(e)

    const out = getData(e.currentTarget.elements, true)
    if (out) {
      onSubmit(out as A)
    }
  }

  return <form
    autoComplete='false'
    className={cn('iq-form', className, direction)}
    onSubmit={onFormSubmit}
    ref={formRef}
    noValidate
    {...otherProps}
  >
    { children }
    <button type='submit' style={{ display: 'none' }}>Submit</button>
  </form>
}

export default BaseForm
