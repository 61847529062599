import React from 'react'
import { SVGComponent } from './types'

const IconCapslock: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className={'iq-svg-icon capslock'}
  style={style}
>
  <path d='M12 8.41L16.59 13 18 11.59l-6-6-6 6L7.41 13 12 8.41zM6 18h12v-2H6v2z' />
  <path d='M0 0h24v24H0z' fill='none'/>
</svg>)

export default IconCapslock
