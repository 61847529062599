import React, {
  CSSProperties,
  ReactNode,
  Ref, useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { equals } from 'ramda'
import BaseInput, { InputAction } from './BaseInput'
import { IQInputElement, IQTheme } from '../../../../types'
import { ItemProps } from '../../list/List'
import { IconExpandMore } from '../../vector/Vector'
import { emitInputChanged } from '../../modal/keyboard/utils'
import ModalList from '../../modal/ModalList'

export interface SelectProps<A> {
  className?: string
  style?: CSSProperties
  value?: A
  entries: A[] | (() => Promise<A[]>)
  title?: string
  hint?: ReactNode
  render: (raw: A) => ItemProps
  onChange?: (value?: A) => void
  modalTheme?: IQTheme
  name: string
  actions?: InputAction[]
  placeholder?: string
  readOnly?: boolean
  autoFocus?: boolean
  hidden?: boolean
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void
  checkValidity?: (value?: A) => boolean
  disabled?: boolean
  required?: boolean
  emptyValue?: A
  emptyItem?: ItemProps
}

const Select = function <A>(props: SelectProps<A> & {
  selectRef?: Ref<IQInputElement<A>>
}) {
  const {
    actions, entries, render, title, onChange = () => {}, modalTheme, selectRef: ref,
    value: defaultValue, checkValidity = () => true, required,
    emptyItem = {key: 'empty', title: 'Nothing'}, emptyValue,
    ...inputProps
  } = props

  const [listIsOpened, setListIsOpened] = useState(false)
  const [value, setValue] = useState<A | undefined>(defaultValue || emptyValue)
  const inputRef = useRef<IQInputElement<A>>(null)

  const inputActions = useMemo(
    () => {
      return [...(actions || []), {
        key: 'show_entries',
        disabled: true,
        title: <IconExpandMore />,
        type: 'internal_action'
      } as InputAction]
    },
    [actions]
  )

  const getSafeValue = (val?: A) => {
    return val ? (render(val).title as string) || '' : ''
  }

  const checkValue = (val?: A) => {
    if (required && equals(val, emptyValue)) {
      return false
    }

    try {
      return checkValidity(val)
    } catch (e) {
      return false
    }
  }

  useEffect(
    () => {
      setValue(defaultValue || emptyValue)
    },
    [defaultValue, emptyValue]
  )

  useEffect(() => {
    if (inputRef.current) {
      const newValue = getSafeValue(value)
      if (checkValue(value)) {
        emitInputChanged(inputRef.current, newValue, newValue.length, false)
      }
    }
  }, [value])

  useLayoutEffect(
    () => {
      const el: Partial<IQInputElement<A>> | null = inputRef.current
      if (el) {
        el.getValue = () => value || emptyValue

        el.setValue = (val: any, focus = true) => {
          setValue(val)
          const newValue = getSafeValue(val)
          emitInputChanged(el as HTMLInputElement, newValue, newValue.length, focus)

          if (onChange) {
            onChange(val)
          }
        }
      }

      if (ref) {
        if (typeof ref === 'function') {
          ref(el as IQInputElement<A>)
        } else {
          (ref as any).current = el
        }
      }
    },
    [ref, inputRef, value, emptyValue]
  )

  const renderItem = useCallback((item: A | undefined) => {
    if (equals(item, emptyValue)) {
      return emptyItem
    } else {
      return render(item as A)
    }
  }, [render])

  const modalEntries = useMemo(() => {
    if (required) {
      return entries
    }

    if (typeof entries === 'function') {
      return () => entries().then((val) => [emptyValue, ...val])
    } else {
      return [emptyValue, ...entries]
    }
  }, [required, entries])

  // @ts-ignore
  return <>
    <BaseInput
      readOnly
      customValue
      {...inputProps}
      type='text'
      className='iq-select'
      inputRef={inputRef}
      actions={inputActions}
      value={value}
      stringify={() => getSafeValue(value)}
      parse={() => value}
      checkValidity={() => checkValue(value)}
      onFocus={() => setListIsOpened(true)}
      onClick={() => setListIsOpened(true)}
    />

    <ModalList
      selected={value}
      onClose={(selected) => {
        setValue(selected as A)
        if (inputRef.current) {
          inputRef.current.focus()
        }

        if (!required) {
          if (!equals(value, selected)) {
            onChange(selected as A)
          }
        } else {
          // @ts-ignore
          if (selected !== undefined && !equals(value, selected)) {
            onChange(selected as A)
          }
        }

        setListIsOpened(false)
      }}
      title={title}
      isOpened={listIsOpened}
      items={modalEntries as A[] | (() => Promise<A[]>)}
      render={renderItem}
      theme={modalTheme}
    />
  </>
}

export default Select
