import React, { ReactNode, FunctionComponent } from 'react'
import cn from 'classnames'
import Button from '../button/Button'
import { DomNode } from '../../../types'
import './layout.scss'

export interface HeaderProps extends DomNode {
  title?: string
  subtitle?: string
  logo?: ReactNode
  breadcrumb?: Breadcrumb[]
  backIcon?: ReactNode
  footer?: ReactNode
  extra?: ReactNode
  onBack?: () => void
}

export interface ContentProps extends DomNode {
  children?: ReactNode
}

export interface Breadcrumb {
  title: string
  path: string
}

export const toggleBodyClass = (cls: string, enabled: boolean) => {
  if (enabled) {
    document.body.classList.add(cls)
  } else {
    document.body.classList.remove(cls)
  }
}

const Header: FunctionComponent<HeaderProps> = props => {
  const { children, className, onBack, backIcon, title, subtitle, logo, extra, ...otherProps } = props

  const renderLogo = () => {
    return onBack ? <div className='iq-header-back-icon-wrapper' onClick={onBack}>
      { backIcon || <Button title='Back' /> }
    </div> : !!logo && <div
      className={ cn('iq-header-logo', {
        large: !!subtitle
      }) }
    >
      { logo }
    </div>
  }

  const renderTitles = () => {
    if (!title && !subtitle) {
      return false
    }

    return <div className={ 'iq-header-title' }>
      { title && <h5>{ title }</h5> }
      { subtitle && <p>{ subtitle }</p> }
    </div>
  }

  const renderExtra = () => {
    if (!extra) {
      return false
    }

    return <div className={ 'iq-header-extra' }>{ extra }</div>
  }

  return <header className={ cn('iq-layout-header', className) } {...otherProps}>
    <div className={ 'iq-header-base-content' }>
      { renderLogo() }
      { renderTitles() }
      { renderExtra() }
    </div>
    { children && <div className={ 'header-content' }>{ children }</div> }
  </header>
}

const Footer: FunctionComponent<DomNode> = ({ children, className, ...props }) =>
  <footer className={ cn('iq-layout-footer', className) } {...props}>
    { children }
  </footer>

const Content: FunctionComponent<ContentProps> = ({ children, className, style = {}, ...props }) =>
  <main className='iq-layout-content'>
    <Scroll>
      <div style={{ padding: '1.6rem', ...style }} className={className}  {...props}>
        { children }
      </div>
    </Scroll>
  </main>

const Scroll = ({ children, className, ...props }: ContentProps ) =>
  <div className={cn('iq-layout-scroll-container', className)}>
    <div className='iq-scroll-content' {...props}>
      { children }
    </div>
  </div>

const Layout: FunctionComponent<DomNode> = ({
  className, children, ...props
}) => <section
  className={ cn('iq-layout', className) }
  {...props}
>
  { children }
</section>

const Block = ({ className, children, ...props }: ContentProps) => <div
  className={ cn('iq-block', className) }
  {...props}
>
  { children }
</div>

export {
  Header, Footer, Scroll, Content, Layout, Block
}
