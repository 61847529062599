import React, { useEffect, useState, useCallback, ReactElement } from 'react'
import { differenceWith, equals } from 'ramda'
import { DomNode } from '../../../types'
import { load, list, remove } from './storage'
import { Audio, Video, IQMediaProps } from './Media'

type InnerPlaylistProps = IQMediaProps

export interface PlaylistProps extends DomNode {
  entries: string[]
  tag: string
  autoPlay?: IQMediaProps['autoPlay']
  onEnded?: IQMediaProps['onEnded']
  onProgress?: IQMediaProps['onProgress']
  controlsRef?: IQMediaProps['controlsRef']
}

function Playlist(
  Media: (props: InnerPlaylistProps) => ReactElement
) {
  return (props: PlaylistProps) => {
    const { entries, onProgress, tag, ...otherProps } = props
    const [index, setIndex] = useState(0)

    useEffect(() => {
      const entriesDiff = differenceWith<string, string>(equals)

      const diff = async () => {
        const stored = await list(tag)

        entriesDiff(entries, stored).forEach((src) => load(src, tag).catch(() => {}))
        return remove(entries.length ? entriesDiff(stored, entries) : [])
      }

      // tslint:disable-next-line
      diff().catch(console.error)
    }, [entries])

    const next = useCallback(() => {
      setIndex(index + 1)
    }, [index, entries])

    if (!entries.length) {
      return null
    }

    return <Media
      autoPlay
      tag={tag}
      src={entries[index % entries.length]}
      onEnded={next}
      {...otherProps}
    />
  }
}

const AudioPlaylist = Playlist(Audio)
const VideoPlaylist = Playlist(Video)

export {
  AudioPlaylist,
  VideoPlaylist
}
