import React, { FunctionComponent, ReactNode, CSSProperties, useEffect, useState } from 'react'
import { IconSpinner } from '../vector/Vector'
import * as ReactDOM from 'react-dom'
import cn from 'classnames'
import { Motion, spring } from 'react-motion'
import { IQMessage } from '../../../types'
import './message.scss'

export interface MessageProps {
  title?: ReactNode
  subtitle?: ReactNode
  style?: CSSProperties
  className?: string
  timeout?: number
  type?: IQMessage
  onClick?: () => void
  onClose?: () => void
}

interface Props extends MessageProps {
  close: () => {}
  isVisible: boolean
}

function getContainer() {
  const oldContainer = document.getElementById('iqMessageContainer')
  if (oldContainer) {
    return oldContainer
  }

  const newContainer = document.createElement('div')
  newContainer.classList.add('iq-message-container')
  newContainer.id = 'iqMessageContainer'
  document.body.appendChild(newContainer)

  return newContainer
}

const container = getContainer()

const MessageDOM: FunctionComponent<Props> = (props) => {
  const [createdAt] = useState(Date.now())

  const {
    title, isVisible, type = 'default', style = {}, onClick = () => {},
    close, subtitle, timeout, onClose
  } = props

  const closeMessage = () => {
    close()
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (!timeout) {
      return
    }

    const timer = setTimeout(closeMessage, timeout - Date.now() + createdAt)
    return () => clearTimeout(timer)
  }, [timeout, createdAt])

  const renderIcon = () => {
    if (type === 'loading') {
      return <IconSpinner />
    }

    return false
  }

  const isWeak = document.body.classList.contains('iq-weak')
  return <Motion
    defaultStyle={{
      opacity: 0,
      offset: -90
    }}
    style={{
      opacity: spring(isVisible ? 1 : 0, {
        precision: isWeak ? 100 : 0.1
      }),
      offset: spring(isVisible ? 0 : -90, {
        stiffness: isVisible ? 500 : 150,
        damping: isVisible ? 50 : 10,
        precision: isVisible ? 100 : 0.1
      })
    }}
  >
    {motion => <div
      className={cn('iq-message', `iq-message-type-${type}`)}
      onClick={(e) => {
        e.preventDefault()
        onClick()
        closeMessage()
      }}
      style={{
        ...style,
        opacity: motion.opacity,
        WebkitTransform: `translate3d(0, ${motion.offset}px, 0)`,
        transform: `translate3d(0, ${motion.offset}px, 0)`,
      }}
    >
      <div className='iq-message-title'>{ title }</div>
      <div className='iq-message-subtitle'>{ subtitle }</div>
      { renderIcon() }
    </div>}
  </Motion>
}

function makeModalProps<T extends MessageProps>(props: T, isVisible: boolean) {
  return {
    ...props,
    isVisible
  }
}

function Message(props: MessageProps) {
  const div = document.createElement('div')
  container.appendChild(div)

  let willClosing = false
  const close = () => {
    if (willClosing) {
      return
    }

    willClosing = true

    ReactDOM.render(
      <MessageDOM
        { ...makeModalProps(defaultProps, false) }
      />,
      div
    )

    setTimeout(() => {
      const unmountResult = ReactDOM.unmountComponentAtNode(div)
      if (unmountResult && div.parentNode) {
        div.parentNode.removeChild(div)
      }
    }, 1000)
  }

  const defaultProps = {
    close,
    ...props
  } as Props

  ReactDOM.render(
    <MessageDOM {...makeModalProps(defaultProps, false)} />,
    div, () =>
      ReactDOM.render(<MessageDOM {...makeModalProps(defaultProps, true)} />, div)
  )

  return close
}

function Loading<T>(promise: Promise<T>, props: Partial<MessageProps> = {}) {
  const close = Message({
    ...props,
    type: 'loading',
  })

  promise.then(close).catch(close)
  return promise
}

Message.Loading = Loading

export default Message

export { container as messagesContainer }
