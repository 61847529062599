const replaceStringFragment = (
  value: string,
  start: number,
  end: number,
  newFragment = ''
) => value.slice(0, start) + newFragment + value.slice(start + end)

const eventInitDict = {
  bubbles: true,
  cancelable: true
}

export function emitInputChanged(
  input: HTMLInputElement,
  value: string,
  lastCursorPosition: number,
  focus: boolean = true
) {
  input.value = value
  input.dispatchEvent(
    new Event('input', eventInitDict)
  )

  if (input.form) {
    input.form.dispatchEvent(
      new Event('change', eventInitDict)
    )
  }

  input.setSelectionRange(lastCursorPosition, lastCursorPosition)
  if (focus) {
    input.focus()
  }
}

export function onSubmitClick(form?: HTMLFormElement | null) {
  if (form) {
    form.dispatchEvent(new Event('submit', eventInitDict))
  }
}

function onBackspaceClick(input: HTMLInputElement) {
  const start = input.selectionStart || 0
  const end = input.selectionEnd || 0

  if (start === 0 && end === 0) {
    return
  }

  if (start !== end) {
    emitInputChanged(input, replaceStringFragment(input.value, start, end), start)
  } else if (start > 0) {
    const pos = start - 1
    emitInputChanged(input, replaceStringFragment(input.value, pos, start), pos)
  } else {
    emitInputChanged(input, '', 0)
  }
}

function onKeyClick(input: HTMLInputElement, key: string) {
  switch (key) {
    case 'cancel': return
    case 'enter': return onSubmitClick(input.form)
    case 'backspace': return onBackspaceClick(input)
    default:
      if (input.selectionStart !== input.selectionEnd) {
        onBackspaceClick(input)
      }
      break
  }

  const cursorPosition = input.selectionStart || 0
  emitInputChanged(
    input,
    replaceStringFragment(input.value, cursorPosition, 0, key),
    cursorPosition + 1
  )
}

export {
  onKeyClick
}
