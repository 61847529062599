import React from 'react'
import { SVGComponent } from './types'

const Indeterminate: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className='iq-svg-icon checkbox indeterminate'
  style={style}
>
  <defs>
    <path id='a' d='M0 0h24v24H0z'/>
  </defs>
  <clipPath id='b'>
    <use xlinkHref='#a' overflow='visible'/>
  </clipPath>
  {/* tslint:disable-next-line */}
  <path clipPath='url(#b)' d='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z'/>
  <path d='M0 0h24v24H0z' fill='none'/>
</svg>)

const Checked: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className='iq-svg-icon checkbox checked'
  style={style}
>
  <path d='M0 0h24v24H0z' fill='none' />
  {/* tslint:disable-next-line */}
  <path d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z' />
</svg>)

const Blank: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className='iq-svg-icon checkbox blank'
  style={style}
>
  <path d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z' />
  <path d='M0 0h24v24H0z' fill='none'/>
</svg>)

export {
  Indeterminate,
  Checked,
  Blank
}
