import React, { useEffect, useLayoutEffect, useState, useRef, useCallback, Ref, ReactElement } from 'react'
import cn from 'classnames'
import { load } from './storage'
import { DomNode } from '../../../types'

type InnerMediaProps<T> = {
  key?: string
  elementRef: Ref<T>
  autoPlay?: boolean
  onEnded?: () => void
  onTimeUpdate: () => void
  children: ReactElement
}

export type MediaControls = {
  play: () => void
  pause: () => void
  stop: () => void
  seek: (position: number) => void
  volume: (level: number) => void
}

export interface IQMediaProps extends DomNode {
  src: string
  tag: string
  autoPlay?: boolean
  onEnded?: () => void
  onProgress?: (progress: number) => void
  controlsRef?: Ref<MediaControls>
}

function MediaElement<T extends HTMLMediaElement>(
  wrapperClassName: string,
  Media: (props: InnerMediaProps<T>) => ReactElement<T>
) {
  return (props: IQMediaProps) => {
    const {
      className, src, autoPlay,
      onEnded, onProgress, tag,
      controlsRef, ...otherProps
    } = props
    const [blob, setBlob] = useState()
    const mediaRef = useRef<T>(null)
    const volumeRef = useRef<number>(1)

    useLayoutEffect(() => {
      if (!controlsRef || !mediaRef.current) {
        return
      }

      const ref: MediaControls = {
        play: () => mediaRef.current && mediaRef.current.play(),
        pause: () => mediaRef.current && mediaRef.current.pause(),
        stop: () => {
          if (mediaRef.current) {
            mediaRef.current.pause()
            mediaRef.current.currentTime = 0
          }
        },
        seek: (position) => {
          if (mediaRef.current) {
            mediaRef.current.currentTime = position
          }
        },
        volume: (level) => {
          if (mediaRef.current) {
            volumeRef.current = level
            mediaRef.current.volume = level
          }
        },
      }

      if (typeof controlsRef === 'function') {
        controlsRef(ref)
      } else {
        (controlsRef as any).current = ref
      }
    }, [controlsRef, volumeRef, mediaRef.current])

    useEffect(() => {
      if (mediaRef.current) {
        mediaRef.current.volume = volumeRef.current
      }
    }, [mediaRef.current])

    const onTimeUpdate = useCallback(() => {
      if (!onProgress || !mediaRef.current) {
        return
      }

      onProgress(mediaRef.current.currentTime / mediaRef.current.duration)
    }, [mediaRef.current, onProgress])

    useEffect(() => {
      load(src, tag, (progress) => console.log('Progress', progress))
        .then(setBlob)
        .catch(console.error)
      setBlob(undefined)
    }, [src])

    return <div className={cn(wrapperClassName, className)} {...otherProps}>
      <Media
        key={src}
        elementRef={mediaRef}
        autoPlay={autoPlay}
        onEnded={onEnded}
        onTimeUpdate={onTimeUpdate}
      >
        { blob && <source src={blob} /> }
      </Media>
    </div>
  }
}

const Audio = MediaElement<HTMLAudioElement>(
  'iq-audio',
  ({ elementRef, ...props }) => <audio ref={elementRef} {...props} />
)

const Video = MediaElement<HTMLVideoElement>(
  'iq-video',
  ({ elementRef, ...props }) => <video ref={elementRef} {...props} />
)

export {
  Audio, Video
}
