import React, { CSSProperties, Key, ReactNode } from 'react'
import cn from 'classnames'
import { find, propEq } from 'ramda'
import List from '../ui/list/List'
import { Scroll } from '../ui/layout/Layout'
import './navigation.scss'

export type NavigationRoute = {
  key?: string
  route: string
  title: ReactNode
  canEnter?: () => boolean
  subtitle?: ReactNode
  avatar?: ReactNode
  icon?: ReactNode
  disabled?: boolean
  divider?: boolean
  parentRoute?: Key
  willEnter?: () => boolean
  didEnter?: () => void
  className?: string
}

export type NavigationProps = {
  route?: string
  routes?: NavigationRoute[]
  style?: CSSProperties
  className?: string
  onNavigate?: (route: string) => void
  mode?: 'compact' | 'floating' | 'default'
}

function Navigation(props: NavigationProps) {
  const {
    className, style, routes, route,
    onNavigate = () => {},
  } = props

  return <Scroll className={cn('iq-navigation', className)}>
    <List<NavigationRoute>
      style={style}
      items={routes || []}
      selected={find(propEq('route', route), routes || [])}
      selectionMode='item'
      onSelect={(item) => {
        const val = item as NavigationRoute

        if (val) {
          if (!val.willEnter || val.willEnter()) {
            onNavigate(val.route)
            if (val.didEnter) {
              val.didEnter()
            }
          }
        }
      }}
      render={({ className: cls, divider, ...item }) => ({
        key: (item.route || item.key || item.title) as string,
        ...item,
        className: cn(cls, { divider }),
        disabled: divider || item.disabled || (item.canEnter !== undefined && !item.canEnter())
      })}
    />
  </Scroll>
}

export default Navigation
