import React, {
  MouseEvent, TouchEvent, ReactNode,
  ReactFragment, ReactPortal, ReactElement, createElement, Ref, useCallback
} from 'react';
import { pascalCase } from 'change-case'
import cn from 'classnames'
import { DomNode, IQAlign, IQTheme } from '../../../types';
import { absorbEvent } from '../../../utils/events'
import * as Icons from '../vector/Vector'
import './button.scss'

export interface ButtonProps extends DomNode {
  title?: ReactNode
  inline?: boolean
  flat?: boolean
  submit?: boolean
  name?: string
  disabled?: boolean
  textAlign?: IQAlign
  primary?: boolean
  secondary?: boolean
  danger?: boolean
  caution?: boolean
  children?: ReactElement | ReactFragment | ReactPortal | false | null | undefined
  focusable?: boolean
  alt?: string
  icon?: string
  onClick?: (event: MouseEvent | TouchEvent) => void
  onFocus?: () => void
  theme?: IQTheme
  tabIndex?: number
  buttonRef?: Ref<HTMLButtonElement>
}

const renderIcon = (icon?: string) => {
  if (!icon) {
    return false
  }

  const iconName = pascalCase(icon)
  if (Icons[iconName]) {
    return createElement(Icons[iconName], {}, null)
  }

  if (Icons['Icon' + iconName]) {
    return createElement(Icons['Icon' + iconName], {}, null)
  }

  return false
}

const BaseButton = (props: ButtonProps & {
  dataValue?: string
}) => {
  const {
    title, children, inline, flat, disabled, className, dataValue, buttonRef,
    textAlign = 'center', onClick = () => {}, style, onFocus,
    submit, alt, icon, primary, secondary, theme, name,
    danger, caution, tabIndex = -1, focusable, id,
  } = props

  const handleClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    if (focusable) {
      e.currentTarget.focus()
    }

    if (onClick) {
      onClick(e)
    }
  }, [onClick])

  return <button
    id={id}
    ref={buttonRef}
    tabIndex={tabIndex === undefined ? focusable ? 1 : -1 : undefined}
    className={cn('iq-button', className, textAlign, {
      inline, flat, primary, secondary,
      danger, caution
    }, theme && `iq-${theme}`)}
    title={alt}
    disabled={disabled}
    style={style}
    data-value={dataValue}
    name={name}
    type={submit ? 'submit' : 'button'}
    onContextMenuCapture={(e) => {
      absorbEvent(e)
      handleClick(e)
    }}
    onFocus={onFocus}
    onMouseDown={absorbEvent}
    onMouseUp={absorbEvent}
    onClick={handleClick}
  >
    { renderIcon(icon) }
    { title }{ children }
  </button>
}

const Group = ({ className, children, ...props }: DomNode & {
  children: ReactNode | ReactNode[]
}) => {
  return <div className={cn('iq-buttons-group', className)} {...props}>
    { children }
  </div>
}

type TButton = typeof BaseButton & {
  Group: typeof Group
}

const Button: any = BaseButton
Button.Group = Group

export default Button as TButton
