import { SyntheticEvent } from 'react'

function absorbEvent(e: SyntheticEvent | KeyboardEvent | undefined) {
  if (!e) {
    return e
  }

  e.preventDefault()
  e.stopPropagation()
  return e
}

export {
  absorbEvent
}
