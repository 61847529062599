import React, { Ref, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { InputAction } from './BaseInput'
import TypedInput, { TypedInputProps } from './TypedInput'
import KeyboardText from '../../modal/keyboard/KeyboardText'
import KeyboardNumber from '../../modal/keyboard/KeyboardNumber'
import { IconKeyboard } from '../../vector/Vector'
import { IQInputElement } from '../../../../types'

export default function <A>(props: TypedInputProps<A> & {
  inputRef?: Ref<IQInputElement<any>>
}) {
  const { actions, inputRef: ref, type = 'text', useKeyboardAction, ...inputProps } = props
  const [keyboardIsOpened, setKeyboardIsOpened] = useState(false)
  const inputRef = useRef<IQInputElement<A>>(null)

  const inputActions = useMemo(
    () => {
      const isTouch =
        window.matchMedia('(any-pointer: coarse)').matches ||
        document.body.classList.contains('iq-touch')

      if (isTouch || useKeyboardAction) {
        return [...(actions || []), {
          key: 'show_keyboard',
          title: <IconKeyboard />,
          type: 'internal_action',
          onClick: () => setKeyboardIsOpened(true)
        } as InputAction]
      } else {
        return actions
      }
    },
    [actions]
  )

  const renderKeyboard = useCallback(
    () => {
      const keyboardProps = {
        value: inputRef.current ? inputRef.current.getValue() : undefined,
        checkValidity: props.checkValidity,
        isOpened: keyboardIsOpened,
        stringify: props.stringify,
        parse: props.parse,
        onClose: () => {
          setKeyboardIsOpened(false)
          if (inputRef.current) {
            inputRef.current.focus()
          }
        },
        onSubmit: (value?: A) => {
          if (inputRef.current && value !== undefined) {
            inputRef.current.setValue(value)
          }
        },
      }

      if (type === 'number' || type === 'float' || type === 'tel') {
        // @ts-ignore
        return <KeyboardNumber {...keyboardProps} />
      } else {
        // @ts-ignore
        return <KeyboardText type={type} {...keyboardProps} />
      }
    },
    [
      type, keyboardIsOpened, props.checkValidity, inputRef.current,
      props.stringify, props.parse
    ]
  )

  useLayoutEffect(
    () => {
      if (ref) {
        if (typeof ref === 'function') {
          ref(inputRef.current)
        } else {
          (ref as any).current = inputRef.current
        }
      }
    },
    [inputRef, ref]
  )

  return <>
    <TypedInput<A>
      type={type}
      customValue={false}
      inputRef={inputRef}
      actions={inputActions}
      {...inputProps}
    />

    { renderKeyboard() }
  </>
}
