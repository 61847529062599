import React from 'react'
import { SVGComponent } from './types'

const IconPause: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className={ 'iq-svg-icon pause' }
  style={style}
>
  <path d='M6 19h4V5H6v14zm8-14v14h4V5h-4z'/>
  <path fill='none' d='M0 0h24v24H0z'/>
</svg>)

export default IconPause
