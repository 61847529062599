import React from 'react'
import cn from 'classnames'
import BaseModal, { ModalProps } from './BaseModal'
import IFrame from '../iframe/IFrame'
import Button from '../button/Button'
import IconClose from '../vector/IconClose'

export interface IframeProps extends ModalProps<string> {
  src: string
}

const ModalIframe = (props: IframeProps) => {
  const {
    className, src,
    ...modalProps
  } = props

  return <BaseModal
    fullscreen
    { ...modalProps }
    content={src}
    className={ cn('iq-modal-iframe', className) }
    title={ <div>
      <Button flat title={ <IconClose /> } onClick={ props.onClose } />
      <u>{ src }</u>
    </div> }
  >
    {(link) => <IFrame src={ link } />}
  </BaseModal>
}

ModalIframe.show = function ({ src, className, ...props }: IframeProps) {
  const cbs = {
    reject: () => {}
  }

  return BaseModal.show<string>({
    fullscreen: true,
    ...props,
    className: cn('iq-modal-iframe', className),
    content: src,
    title: <div>
      <Button flat title={ <IconClose /> } onClick={ () => cbs.reject() } />
      <u>{ src }</u>
    </div>,
    children: (link, _, reject) => {
      cbs.reject = reject

      return () => [<IFrame key={'iframe'} src={ link } />, {
        onApply: () => {}
      }]
    }
  })
}

export default ModalIframe
