import cn from 'classnames'
import React from 'react'
import { IconReturn, IconLanguage, IconCapslock, IconBackspace } from '../../vector/Vector'

export type Lang = 'ru' | 'en'
export type ShiftFn = (value: boolean) => void
export type SetLangFn = (value: Lang) => void

const languages = {
  ru: 'Русский',
  en: 'English'
}

function getNextLanguage(current: Lang): Lang {
  return current === 'ru' ? 'en' : 'ru'
}

const keyBackspace = {
  key: 'backspace',
  title: <IconBackspace />,
  action: 'backspace',
}

const keyShift = (
  pressed: boolean,
  setPressed: ShiftFn,
  className?: string
) => ({
  key: 'shift',
  action: 'shift',
  title: <IconCapslock />,
  className: cn(className, {
    accent: pressed
  }),
  onClick: () => setPressed(!pressed)
})

const keySend = {
  key: 'send',
  title: <IconReturn />,
  action: 'enter',
  className: 'x2 primary',
}

const keySpaceBar = (lang: Lang) => ({
  key: 'space_bar',
  title: languages[lang],
  action: ' ',
  className: 'space-bar accent',
})

const keySwitchLanguage = (
  lang: Lang,
  setLang: SetLangFn
) => ({
  key: 'switch_layout_to_english',
  title: <IconLanguage />,
  action: 'switch_layout',
  onClick: () => setLang(getNextLanguage(lang))
})

export {
  keySend,
  keyShift,
  keySpaceBar,
  keyBackspace,
  keySwitchLanguage
}
