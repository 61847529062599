import React, { CSSProperties, ReactNode, useState } from 'react'
import cn from 'classnames'
import BaseModal from './BaseModal'
import List, { ItemProps } from '../list/List'
import { IQTheme } from '../../../types'

export interface ModalListProps<A> {
  items: A[] | (() => Promise<A[]>)
  render: (item: A, index: number, items: A[]) => ItemProps
  selected?: A | A[]
  className?: string
  selectionMode?: 'single' | 'multiple' | 'sequence' | 'item'

  theme?: IQTheme
  title?: ReactNode
  isOpened?: boolean
  onClose?: (selected?: A | A[]) => void
  onShow?: () => void
  persistent?: boolean

  style?: CSSProperties
  errorView?: ReactNode
  emptyView?: ReactNode
  onLoaded?: (data: A[]) => void
  onError?: (e: Error) => void
  onEmpty?: () => void
  isEmpty?: (data: A[] | any | null | undefined) => boolean
  isError?: (data: A[] | any | null | undefined) => boolean
}

const ModalList = function <A>(props: ModalListProps<A>) {
  const {
    className, items, render, selectionMode = 'single',
    selected, onClose = () => {},
    ...modalProps
  } = props

  const [selection, setSelection] = useState<A | A[] | undefined>(selected)

  return <BaseModal<A[]>
    content={items}
    { ...modalProps }
    onClose={() => onClose(selection)}
    className={ cn('iq-modal-list', className) }
    onApply={() => onClose(selection)}
    footerActions={
      (selectionMode === 'single' || selectionMode === 'item') ? [] :
        [{ key: 'apply', title: 'OK', submit: true }]
    }
  >
    {(entries: A[]) => <List<A>
      items={entries}
      selected={selected}
      render={render}
      selectionMode={selectionMode}
      onSelect={(value) => {
        if (selectionMode === 'single' || selectionMode === 'item') {
          onClose(value)
        } else {
          setSelection(value)
        }
      }}
    />}
  </BaseModal>
}

ModalList.show = function <A>({
  selectionMode = 'single',
  items, render, selected, className,
  ...props
}: ModalListProps<A>) {
  const state = { selected: selected || [] }

  return BaseModal.show<A | A[]>({
    ...props,
    content: items,
    selected: selected,
    className: cn('iq-modal-list', className),
    footerActions: (selectionMode === 'single' || selectionMode === 'item') ? [] : [{
      key: 'apply', title: 'OK', submit: true, primary: true,
    }],
    // @ts-ignore
    children: (entries: A[], resolve) => {
      return () => {
        return [
          <List<A>
            key='list'
            items={entries}
            selected={selected}
            render={render}
            selectionMode={selectionMode}
            onFocus={(item) => {
              if (selectionMode === 'single' || selectionMode === 'item') {
                state.selected = item
              }
            }}
            onSelect={(item) => {
              if (selectionMode === 'single' || selectionMode === 'item') {
                resolve(item)
              } else {
                state.selected = item
                console.log(item)
              }
            }}
          />, {
          onApply: () => resolve(state.selected)
        }]
      }
    }
  })
}

export default ModalList
