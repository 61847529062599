import BaseModal from './BaseModal'
import ModalList from './ModalList'
import ModalIframe from './ModalIframe'
import './modal.scss'

type TModal = typeof BaseModal & {
  List: typeof ModalList
  Iframe: typeof ModalIframe
}

const Modal: any = BaseModal
Modal.List = ModalList
Modal.Iframe = ModalIframe

export default Modal as TModal
