const easeIn = (power: number) => (t: number) => Math.pow(t, power)
const easeOut = (power: number) => (t: number) => 1 - Math.abs(Math.pow(t - 1, power))
const easeInOut = (power: number) => (t: number) => {
  if (t < .5) {
    return easeIn(power)(t * 2) / 2
  } else {
    return easeOut(power)(t * 2 - 1) / 2 + 0.5
  }
}

export default {
  linear: easeInOut(1),

  easeInQuad: easeIn(2),
  easeOutQuad: easeOut(2),
  easeInOutQuad: easeInOut(2),

  easeInCubic: easeIn(3),
  easeOutCubic: easeOut(3),
  easeInOutCubic: easeInOut(3),

  easeInQuart: easeIn(4),
  easeOutQuart: easeOut(4),
  easeInOutQuart: easeInOut(4),

  easeInQuint: easeIn(5),
  easeOutQuint: easeOut(5),
  easeInOutQuint: easeInOut(5)
}
