import React from 'react'
import { SVGComponent } from './types'

const IconReturn: SVGComponent = ({ style }) => (<svg
  xmlns='http://www.w3.org/2000/svg'
  width='24'
  height='24'
  viewBox='0 0 24 24'
  className={'iq-svg-icon return'}
  style={style}
>
  <path d='M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7h-2z'/>
  <path d='M0 0h24v24H0z' fill='none'/>
</svg>)

export default IconReturn
