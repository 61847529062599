import React, { useCallback, useEffect, useRef, Ref, MutableRefObject } from 'react'
import View from '../layout/View'
import { DomNode } from '../../../types'
import './iframe.scss'

export interface IFrameProps extends DomNode {
  src: string
  onLoad?: () => void,
  iframeRef?: Ref<HTMLIFrameElement> | MutableRefObject<HTMLIFrameElement>
  onContextMenu?: (e: React.MouseEvent<HTMLIFrameElement, MouseEvent>) => void
}

const IFrame = (props: IFrameProps) => {
  const iframe = useRef<HTMLIFrameElement>()

  const { src, onLoad = () => {}, ...otherProps } = props

  useEffect(() => {
    const element = document.createElement('iframe')
    element.style.display = 'none'
    document.body.appendChild(element)

    iframe.current = element
    return () => {
      document.body.removeChild(element)
    }
  }, [src])

  const load = useCallback(() => {
    return new Promise((resolve) => {
      setTimeout(() => {
        iframe.current!.src = src
        iframe.current!.onload = () => {
          onLoad()
          resolve(true)
        }
      }, 300)
    })
  }, [src])

  return <View
    {...otherProps}
    content={load}
  >
    {() => <iframe
      ref={props.iframeRef}
      allow='fullscreen'
      src={ src }
      className='iq-iframe'
    />}
  </View>
}

export default IFrame
