import React, { CSSProperties, ReactNode } from 'react'
import BaseKeyboard  from './BaseKeyboard'
import { keyBackspace, keySend, Lang } from './keys'
import { ModalFooterAction } from '../BaseModal'
import { IQTheme } from '../../../../types'

export interface KeyboardNumberProps {
  isOpened?: boolean
  theme?: IQTheme
  value?: number
  className?: string
  style?: CSSProperties
  language?: Lang
  checkValidity?: (value?: number) => boolean
  onSubmit?: (value?: number) => void
  onChange?: (value?: number) => void
  title?: ReactNode
  persistent?: boolean
  footerActions?: ModalFooterAction[]
  onClose?: () => void
  onShow?: () => void
}

const layout = [
  [{key: '7', className: 'x1_5'}, {key: '8', className: 'x1_5'}, {key: '9', className: 'x1_5'}],
  [{key: '4', className: 'x1_5'}, {key: '5', className: 'x1_5'}, {key: '6', className: 'x1_5'}],
  [{key: '1', className: 'x1_5'}, {key: '2', className: 'x1_5'}, {key: '3', className: 'x1_5'}],
  [{key: '.', className: 'x1_5'}, {key: '0', className: 'x1_5'}, {...keyBackspace, className: 'x1_5'}],
  [{...keySend, className: 'primary x2'}]
]

const isValid = (value?: any) => {
  try {
    return Number.parseFloat(value) + '' === value + ''
  } catch (e) {
    return false
  }
}

const KeyboardNumber = (props: KeyboardNumberProps) => {
  const {
    checkValidity = () => true,
    onChange = () => {},
    onSubmit = () => {}, ...keyboardProps
  } = props

  return <BaseKeyboard
    { ...keyboardProps }
    type={'number'}
    checkValidity={(val) => checkValidity(val) && isValid(val)}
    onSubmit={(data) => data && onSubmit(data)}
    onChange={(data) => data && onChange(data)}
    layout={layout}
  />
}

KeyboardNumber.show = ({
  checkValidity = () => true,
  onChange = () => {},
  onSubmit = () => {},
  ...props
}: KeyboardNumberProps) => BaseKeyboard.show({
  ...props,
  layout,
  type: 'number',
  checkValidity: (val) => checkValidity(val) && isValid(val),
  onSubmit: (data) => data && onSubmit(data),
  onChange: (data) => data && onChange(data),
})

export default KeyboardNumber
